import { formatDateTime ,wxConfig,shares} from 'common/methods';
import React, { useEffect, useState } from 'react';
import Css from 'public/css/detail.module.css';
import { Link } from 'react-router-dom';
import { ROOT,globalOss } from 'config';
import { showFooter } from "page/main";
function Deatil(props) {
    const [data, setData] = useState(null);
    const ids = props.match.params.detail;
    useEffect(() => {
        loading(ids);
        showFooter();
        shares('助企业降本增效,促个人灵活就业', window.location.href);
        wxConfig(window.location.href);
    }, [ids]);
    window.scrollTo(0, 0);
    function loading(id) {
        fetch(`${ROOT}/website/phone//getArticleInfo?articleId=${id}`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(res => {
                if (res.code + '' === '1') setData(res.data);
            });
    }
    return (
        <div>
            <div className={Css.top}>
                <p>
                    <span>来源：{data && data.origin}</span>
                    <span>时间：{formatDateTime(data && data.updateTime, 'yyyy-MM-dd')}</span>
                </p>
                <Link to="/info">
                    <span>返回上一级</span>
                </Link>
            </div>
            <article>
                <div className={Css.titl}>
                    <p>{data && data.title && data.title}</p>
                </div>
                {data && data.content ? (
                    <div
                        className={Css.con}
                        dangerouslySetInnerHTML={{ __html: `${data && data.content}` }}
                    ></div>
                ) : null}
            </article>
            {data && data.content ? (
                <div className={Css.nav}>
                    {data && data.pre === undefined ? null : (
                        <p onClick={() => loading(data && data.pre.articleId)}>
                            <span>上一篇</span>
                            {data && data.pre.title}
                        </p>
                    )}
                    {data && data.next === undefined ? null : (
                        <p onClick={() => loading(data && data.next.articleId)}>
                            <span>下一篇</span>
                            {data && data.next.title}
                        </p>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default Deatil;
