import React, { useEffect } from "react";
import { globalOss } from "config";
import Css from "public/css/about.module.css";
import { wxConfig, shares } from "common/methods";
import { showFooter } from "page/main";
function About() {
  window.scrollTo(0, 0);
  useEffect(() => {
    showFooter();
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
  }, []);
  function getHtml(t, c) {
    return (
      <div>
        <p className={Css.zb}>
          {t}：{c}
        </p>
      </div>
    );
  }
  const data = [
    {
      title: "联系电话",
      value: "4001891897",
    },
    {
      title: "公司邮箱",
      value: "yy@xiangong.com",
    },
    {
      title: "联系地址",
      value: "四川省成都市金牛区蜀西路42号三泰魔方B2栋-902室",
      value2: "上海市奉贤区茂园路260号57幢9楼905室",
    },
  ];
  // const data2 = [...data];
  // data2[2].value = "上海市宝山区长建路199号10幢A区040室";
  return (
    <div className={Css.about}>
      {/* <img
        src={require("public/image/new/x_gy_1.png").default}
        width="100%"
        alt=""
      /> */}
      <img
        src={`${globalOss}/phonesite/yuyang.png`}
        style={{ width: "100%" }}
        className="jt"
        alt=""
      />
      <div>
        <p className={Css.title}>关于我们</p>
        <div className={Css.x} />
        <div className={Css.flx}>
          <p>
            <strong>公司简介</strong>
            <br />
            <div className={Css.imgleft}>
              <img
                src={`${globalOss}/phonesite/aboutz.png`}
                width="100px"
                alt=""
              />
              <p>
                成都语阳科技有限公司，成立于2017年，落户在成都金牛区高新技术产业孵化园（三泰魔方商业圈）,核心创始团队皆为金融、法律、财税、人力资源行业资深专家，聚焦以AI人工智能、互联网大数据、云服务等新技术创新开发，打造“闲工——企业用工数智赋能平台。
              </p>
              <p>
                闲工（上海）人力资源有限公司，成立于2021年，坐落于繁华的上海临港南桥科技城、上海人才大厦，负责全面实施“技术+平台+服务”商业模式，推进闲工助力企业用工数智赋能服务落地。
              </p>
              <div style={{ clear: "both" }}></div>
            </div>
            <br />
            <strong>闲工</strong>
            <br />
            <div className={Css.imgleft}>
              一站式灵活用工saas服务平台，提供灵活用工整体解决方案。
            </div>
            <br />
            <strong>愿景</strong>
            <br />
            <div className={Css.imgleft}>
              打造企业用工一体化数智赋能平台，帮助企业用工形式多元化、过程数字化、管理智能化，成为值得信赖的科技创新型人力资源服务提供商。
            </div>
            {/* <br />
            <strong>闲来登</strong>
            <br />
            <div className={Css.imgleft}>
              专业用工管理及即时通讯工具，满足非员工管理的场景需求。
            </div>
            <br />
            <strong>闲力</strong>
            <br />
            <div className={Css.imgleft}>
              是一站式人力资源项目资源对接平台，提升人力资源匹配对接效率。
            </div> */}
          </p>
          <div></div>
        </div>
        <p className={Css.title2}>联系我们</p>
        <div className={Css.x} />
        <p className={Css.title3}>成都总部</p>
        {data.map((i) => getHtml(i.title, i.value))}
        <p
          className={Css.title3}
          style={{
            marginTop: 20,
          }}
        >
          上海总部
        </p>
        {data.map((i) =>
          getHtml(i.title, i.title == "联系地址" ? i.value2 : i.value)
        )}
      </div>
      <div className={Css.dimg}></div>
      <img
        className={Css.bimg}
        src={require("public/image/aboutbottom.png").default}
        alt=""
      />
    </div>
  );
}

export default About;
