import React, { useRef, useEffect, useState } from "react";
import { globalOss, ROOT } from "config";
import Css from "public/css/sub.module.css";
import { wxConfig, shares } from "common/methods";
import { showFooter } from "page/main";
import { Picker } from "antd-mobile";
function Application() {
  const nameRef = useRef(null);
  const telRef = useRef(null);
  const companyName = useRef(null);
  const descRef = useRef(null);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState([]);
  const shenfen = {
    1: "闲者",
    2: "闲友",
    4: "闲主",
    8: "闲商",
  };
  useEffect(() => {
    showFooter();
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
  }, []);
  function save(event) {
    event.preventDefault();
    var exg2 = /^[\u4e00-\u9fa5_a-zA-Z]+$/;
    const span1 = document.getElementsByTagName("span")[1];
    const span2 = document.getElementsByTagName("span")[3];
    const span = document.getElementsByTagName("span")[5];

    if (nameRef.current.value) span1.style.opacity = 0;
    if (telRef.current.value) span2.style.opacity = 0;
    if (companyName.current.value) span.style.opacity = 0;

    if (!telRef.current.value) span2.style.opacity = 1;
    if (!companyName.current.value) span.style.opacity = 1;
    if (!exg2.test(nameRef.current.value)) span1.style.opacity = 1;
    if (
      nameRef.current.value &&
      telRef.current.value &&
      companyName.current.value &&
      exg2.test(nameRef.current.value)
    ) {
      fetch(`${ROOT}/user/appoint/addAppointMent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `name=${window.encodeURIComponent(
          nameRef.current.value
        )}&phone=${window.encodeURIComponent(
          telRef.current.value
        )}&companyName=${window.encodeURIComponent(
          companyName.current.value
        )}&relation=${window.encodeURIComponent(
          32
        )}&remark=${window.encodeURIComponent(descRef.current.value)}`,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code + "" === "1") {
            document.getElementById("su").style.display = "block";
            document.getElementsByTagName("form")[0].style.display = "none";
            document.getElementsByTagName("p")[1].style.display = "none";
          }
        });
    }
  }
  window.scrollTo(0, 0);
  function changeName() {
    const span = document.getElementsByTagName("span")[1];
    var exg = /^[\u4e00-\u9fa5_a-zA-Z]+$/;
    if (!exg.test(nameRef.current.value)) {
      span.style.opacity = 1;
      return;
    }
    if (nameRef.current.value.length > 0) {
      span.style.opacity = 0;
    } else {
      span.style.opacity = 1;
    }
  }
  function changePhone() {
    const span = document.getElementsByTagName("span")[3];
    if (telRef.current.value.length >= 5) {
      span.style.opacity = 0;
    } else {
      span.style.opacity = 1;
    }
  }
  function changeCompanyName() {
    const span = document.getElementsByTagName("span")[5];
    span.style.opacity = !!companyName.current.value ? 0 : 1;
  }
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.getElementsByClassName(
          "adm-picker-header-button"
        )[0].style.color = "#F75D36";
        document.getElementsByClassName(
          "adm-picker-header-button"
        )[1].style.color = "#F75D36";
      }, 1);
    }
  }, [show]);
  return (
    <div>
      <img
        src={require("public/image/bgImg.jpg").default}
        width="100%"
        alt=""
      />

      <div className={Css.cont}>
        <p>
          全国千县免费直投千台就业服务机，如有意向可留下您的联系方式或拨打
          <a href="tel:400-1891897">400-1891897</a>。 期待您的加入！
        </p>
        <div className={Css.success_block} id="su">
          <div className={Css.success}>
            <img
              draggable="false"
              src={require("public/image/success.png").default}
              alt=""
            />
          </div>
          <div className={Css.success_text}>报名成功,我们将尽快与您联系。</div>
        </div>
        <p className={Css.rh}>报名信息</p>
        <form autoComplete="off">
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>您的称呼</span>
            <div className={Css.shu}></div>
            <input
              type="text"
              className={Css.inp}
              ref={nameRef}
              maxLength={10}
              name="name"
              placeholder="请输入我们对您的称呼"
              onChange={changeName}
            />
          </div>
          <span className={Css.span} id="tip1">
            请输入您的姓名!
          </span>
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>联系电话</span>
            <div className={Css.shu}></div>
            <input
              type="tel"
              ref={telRef}
              name="phone"
              className={Css.inp}
              placeholder="请输入您的联系电话"
              maxLength="16"
              onChange={changePhone}
            />
          </div>
          <span className={Css.span}>请输入联系电话!</span>
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>门店/公司名称</span>
            <div className={Css.shu}></div>
            <input
              type="cName"
              ref={companyName}
              name="companyNames"
              className={Css.inp}
              placeholder="请输入门店/公司名称"
              maxLength="50"
              onChange={changeCompanyName}
            />
          </div>
          <span className={Css.span}>请输入门店/公司名称!</span>
          <div className={Css.div3}>
            <b style={{ color: "#F5F5F5" }}>*</b>
            <span className={Css.name}>备注信息</span>
            <div className={Css.shu3}></div>
            <textarea
              rows={5}
              ref={descRef}
              name="remark"
              className={Css.inp3}
              placeholder="请简单描述您的行业或业务需求(0-400字)"
              maxLength="400"
            />
          </div>
          <div className={Css.smit}>
            <div onClick={save}>
              <a>提交</a>
            </div>
            <div>
              <a href="tel:400-1891897">400-1891897</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Application;
