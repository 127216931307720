import React, { useRef, useEffect, useState } from "react";
import { globalOss, ROOT } from "config";
import Css from "public/css/sub.module.css";
import { wxConfig, shares } from "common/methods";
import { showFooter } from "page/main";
import { Picker } from "antd-mobile";
function Sub() {
  const nameRef = useRef(null);
  const telRef = useRef(null);
  const descRef = useRef(null);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState([]);
  const shenfen = {
    1: "用工企业",
    2: "求职者",
    4: "灵工服务商",
    8: "人力服务商",
    16: "业务推荐者",
  };
  useEffect(() => {
    showFooter();
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
  }, []);
  function save(event) {
    event.preventDefault();
    // var exg = /^[0-9]*$/;
    var exg2 = /^[\u4e00-\u9fa5_a-zA-Z]+$/;
    const span1 = document.getElementsByTagName("span")[1];
    const span2 = document.getElementsByTagName("span")[3];
    const span4 = document.getElementsByTagName("span")[5];
    console.log(span4);
    if (nameRef.current.value) span1.style.opacity = 0;
    if (telRef.current.value) span2.style.opacity = 0;
    if (value?.length > 0) span4.style.opacity = 0;
    if (value?.length == 0) span4.style.opacity = 1;
    if (!telRef.current.value) span2.style.opacity = 1;
    if (!exg2.test(nameRef.current.value)) span1.style.opacity = 1;
    // if (!exg.test(telRef.current.value)) span2.style.opacity = 1;
    if (
      nameRef.current.value &&
      telRef.current.value &&
      // exg.test(telRef.current.value) &&
      exg2.test(nameRef.current.value)
    ) {
      fetch(`${ROOT}/user/appoint/addAppointMent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `name=${window.encodeURIComponent(
          nameRef.current.value
        )}&phone=${window.encodeURIComponent(
          telRef.current.value
        )}&relation=${window.encodeURIComponent(
          value[0]
        )}&remark=${window.encodeURIComponent(descRef.current.value)}`,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code + "" === "1") {
            document.getElementById("su").style.display = "block";
            document.getElementsByTagName("form")[0].style.display = "none";
            document.getElementsByTagName("p")[1].style.display = "none";
          }
        });
    }
  }
  window.scrollTo(0, 0);
  function changeName() {
    const span = document.getElementsByTagName("span")[1];
    var exg = /^[\u4e00-\u9fa5_a-zA-Z]+$/;
    if (!exg.test(nameRef.current.value)) {
      span.style.opacity = 1;
      return;
    }
    if (nameRef.current.value.length > 0) {
      span.style.opacity = 0;
    } else {
      span.style.opacity = 1;
    }
  }
  function changePhone() {
    const span = document.getElementsByTagName("span")[3];
    if (telRef.current.value.length >= 5) {
      span.style.opacity = 0;
    } else {
      span.style.opacity = 1;
    }
  }
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.getElementsByClassName(
          "adm-picker-header-button"
        )[0].style.color = "#F75D36";
        document.getElementsByClassName(
          "adm-picker-header-button"
        )[1].style.color = "#F75D36";
      }, 1);
    }
  }, [show]);
  return (
    <div>
      <img src={`${globalOss}/phonesite/sub.png`} width="100%" alt="" />

      <div className={Css.cont}>
        <p>
          请留下您的联系方式，我们会尽快与您联系。
          <br />
          您也可以直接拨打我们的<a href="tel:400-1891897">400-1891897</a>
          服务电话与我们联系。
        </p>
        <div className={Css.success_block} id="su">
          <div className={Css.success}>
            <img
              draggable="false"
              src={require("public/image/success.png").default}
              alt=""
            />
          </div>
          <div className={Css.success_text}>留言成功,我们将尽快与您联系。</div>
        </div>
        <p className={Css.rh}>具体信息</p>
        <form autoComplete="off">
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>您的称呼</span>
            <div className={Css.shu}></div>
            <input
              type="text"
              className={Css.inp}
              ref={nameRef}
              maxLength={10}
              name="name"
              placeholder="请输入我们对您的称呼"
              onChange={changeName}
            />
          </div>
          <span className={Css.span} id="tip1">
            请输入您的姓名!
          </span>
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>联系电话</span>
            <div className={Css.shu}></div>
            <input
              type="tel"
              ref={telRef}
              name="phone"
              className={Css.inp}
              placeholder="请输入您的联系电话"
              maxLength="16"
              onChange={changePhone}
            />
          </div>
          <span className={Css.span}>请输入联系电话!</span>
          <div className={Css.div1}>
            <b>*</b>
            <span className={Css.name}>咨询身份</span>
            <div className={Css.shu}></div>
            {value?.length > 0 ? (
              <p
                onClick={() => {
                  setShow(true);
                }}
                className={Css.check}
              >
                {shenfen[value[0]]}
              </p>
            ) : (
              <p
                className={Css.btn}
                onClick={() => {
                  setShow(true);
                }}
              >
                请选择咨询身份!
              </p>
            )}

            <Picker
              columns={[
                Object.keys(shenfen)?.map((i) => {
                  return {
                    label: shenfen[i],
                    value: i,
                  };
                }),
              ]}
              visible={show}
              onClose={() => {
                setShow(false);
              }}
              value={value}
              onConfirm={(v) => {
                let s = document.getElementsByTagName("span")[5];
                s.style.opacity = 0;
                setValue(v);
              }}
            />
          </div>

          <span className={Css.span}>请选择咨询身份!</span>
          <div className={Css.div3}>
            <b style={{ color: "#F5F5F5" }}>*</b>
            <span className={Css.name}>备注信息</span>
            <div className={Css.shu3}></div>
            <textarea
              rows={5}
              ref={descRef}
              name="remark"
              className={Css.inp3}
              placeholder="请简单描述您的行业或业务需求(0-400字)"
              maxLength="400"
            />
          </div>
          <div className={Css.smit}>
            <div onClick={save}>
              <a>提交</a>
            </div>
            <div>
              <a href="tel:400-1891897">400-1891897</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Sub;
