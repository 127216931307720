import { globalOss, ROOT } from "config";
import React, { useEffect, useState } from "react";
import Css from "public/css/info.module.css";
import { formatDateTime, getImg, wxConfig, shares } from "common/methods";
import { Link } from "react-router-dom";
import { showFooter } from "page/main";
let size = 5;
let count = 2;
function Info() {
  const [data, setData] = useState({});
  let pagesize = 0;
  useEffect(() => {
    showFooter();
    window.scrollTo(0, 0);
    loading(size, 1, false);
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
    return () => {
      size = 5;
      count = 2;
    };
  }, []);
  // window.scrollTo(0, 0);
  function loading(s, p, m, j) {
    fetch(
      `${ROOT}/website/phone/getSysArticleList/?pageSize=${s}&pageNum=${p}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setData({ d: res.data, total: res.totalCount });
        if (pagesize >= res.totalCount) {
          m.innerHTML = "没有了~";
        } else {
          if (m && j) {
            m.innerHTML = "查看更多";
            j.style.display = "block";
          }
        }
      });
  }
  function getMore() {
    const more = document.getElementsByClassName("more")[0];
    const jt = document.getElementsByClassName("jt")[0];
    more.innerHTML = "加载中~";
    jt.style.display = "none";
    const num = size * count;
    count++;
    pagesize = num;
    loading(num, 1, more, jt);
  }
  return !!Object.keys(data).length ? (
    <div className={Css.info}>
      <img
        src={`${globalOss}/phonesite/x_xgzx.png`}
        style={{ width: "100%" }}
      />
      <ul>
        {data.d.map((v) => (
          <Link key={v.newsId} to={`/info/${v.article_id}`}>
            <li>
              <div className={Css.flx}>
                <div>
                  <p className={Css.P}>{v.title}</p>
                  <p>
                    <span>{v.origin}</span>
                    <span>
                      时间：{formatDateTime(v.update_time, "yyyy-MM-dd")}
                    </span>
                  </p>
                </div>
                <div>
                  <img src={getImg(v.img)} alt="" />
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      {/* <hr color="#D8D8D8" /> */}
      <div className={Css.div_footer} onClick={() => getMore()}>
        <div>
          <span className="more">查看更多</span>
        </div>
        <div>
          <img src={require("public/image/jtx.png").default} className="jt" />
        </div>
      </div>
    </div>
  ) : null;
}

export default Info;
