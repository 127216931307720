import { globalOss } from "config";
import React, { useEffect } from "react";
import Css from "public/css/solution.module.css";
import { Link } from "react-router-dom";
import { wxConfig, shares } from "common/methods";
import { showFooter } from "page/main";
const sList = [
  { title: "合法合规", img: "ss1.png" },
  { title: "资金安全", img: "ss2.png" },
  { title: "降低税负", img: "ss3.png" },
  { title: "共享合作", img: "ss4.png" },
];

const xList = [
  [
    ["自主注册", "营业执照即可"],
    ["平台签约", "签署闲工平台服务协议"],
    ["发布任务", `在线发布任务，与闲者确认合作关系`],
    ["任务验收", `闲者提交任务，闲主审核确认`],
    [
      "报酬代发",
      `在线提交代发报酬信息，要求闲商（财税服务商）向闲者发放报酬，并为闲者完税`,
    ],
    ["开取发票", `线上申请发票开具，查看发票物流进度`],
  ],
  [
    ["用户签约", `在线签约，完成身份证、银行卡、手机号信息即可完成`],
    ["提供服务", "为企业提供服务"],
    ["收入", "银行卡直接入账"],
  ],
];

const dList = [
  [
    ["d11", "电商平台", "推广返佣金结算"],
    ["d12", "直播平台", "网红主播佣金结算"],
    ["d13", "教育平台", "线上主播教师结算"],
    ["d14", "微商平台", "推广返佣金结算"],
    ["d15", "传媒公司", "自媒体KOL结算"],
    ["d16", "小说平台", "作家、写手佣金结算"],
    ["d17", "医疗平台", "线上医生佣金结算"],
    ["d18", "知识付费平台", "知识分享者结算"],
  ],
  [
    ["d21", "物流平台", "招募司机结算"],
    ["d22", "展会行业", "讲师、摄像...结算"],
    ["d23", "旅游公司", "导游、导购...结算"],
    ["d24", "外卖平台", "兼职骑手结算"],
    ["d25", "牙科", "牙医佣金结算"],
    ["d26", "O2O平台", "线下接单人员结算"],
    ["d27", "仓库存储", "临时工、分拣员结算"],
    ["d28", "制造行业", "未签订劳动合同者结算"],
    ["d29", "服务型行业", "临时人员结算"],
    ["d210", "商超行业", "收银、保洁结算"],
    ["d211", "连锁店", "兼职临时工结算"],
  ],
  [
    ["d31", "物业公司", "渠道佣金结算"],
    ["d32", "支付公司", "POS推广人员结算"],
    ["d33", "培训机构", "讲师、教练、老师...结算"],
    ["d34", "广告公司", "广告中介费用结算"],
  ],
];

function Solution() {
  
  window.scrollTo(0, 0);
  useEffect(() => {
    showFooter();
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
  }, []);
  return (
    <div>
      <img
        src={require("public/image/new/x_j_jjfa.png").default}
        width="100%"
        alt=""
      />
      <div className={Css.warp}>
        <span className={Css.dxg}>灵活用工整体解决方案</span>
      </div>
      <div style={{ marginTop: 20 }}>
        <img
          src={require("public/image/new/x_j_h.png").default}
          width="100%"
          alt=""
        />
        {/* <div className={Css.cont1}>
                    <p>我们的优势</p>
                    {sList.map(v => (
                        <span key={v.img}>
                            <img src={require(`public/image/${v.img}`).default} alt="" />
                           <b> {v.title}</b>
                        </span>
                    ))}
                </div> */}
      </div>
      {/* <p className="title">闲工服务流程</p> */}
      {/* <div>
                <div>
                    <p className={Css.tit_}>闲主（企业雇主）服务流程</p>
                    <div className={Css.cont2}>
                        {xList[0].map((v, i) => {
                            return !!(i % 2) ? (
                                <div key={i} style={{ width: '70%', display: 'flex' }}>
                                    <div className={Css.imgb}>
                                        <img
                                            src={require('public/image/line.png').default}
                                            width="37"
                                            alt=""
                                        />
                                    </div>
                                    <div className={Css.ls}>
                                        <span className={Css.num}>0{i + 1}.</span>
                                        <div>
                                            <p>{v[0]}</p>
                                            <pre>{v[1]}</pre>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div key={i} className={`${Css.ls} ${Css.w30}`}>
                                    <span className={Css.num}>0{i + 1}.</span>
                                    <div>
                                        <p>{v[0]}</p>
                                        <pre>{v[1]}</pre>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <p className={Css.tit_}>闲者（自由职业者）服务流程</p>
                    <div className={Css.cont2}>
                        {xList[1].map((v, i) => {
                            return !!(i % 2) ? (
                                <div key={i} style={{ width: '70%', display: 'flex' }}>
                                    <div className={Css.imgb}>
                                        <img
                                            src={require('public/image/line.png').default}
                                            width="100%"
                                            alt=""
                                        />
                                    </div>
                                    <div className={Css.ls}>
                                        <span className={Css.num}>0{i + 1}.</span>
                                        <div>
                                            <p>{v[0]}</p>
                                            <pre>{v[1]}</pre>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div key={i} className={`${Css.ls} ${Css.w30}`}>
                                    <span className={Css.num}>0{i + 1}.</span>
                                    <div>
                                        <p>{v[0]}</p>
                                        <pre>{v[1]}</pre>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> */}
      <div className={Css.warp}>
        <span className={Css.dxg}>灵活用工服务人群</span>
      </div>
      {/* <p className="title">灵活用工服务人群</p> */}
      <div className={Css.cont3}>
        <p>服务全国8000万自由职业者</p>
        <p>
          主播、微商、网红、游戏美工、动漫画手、网友大咖、数码博主、
          房产经纪人、投资顾问、服装设计、广告设计、婚礼策划师、职业培训师、
          专车司机、包工头、家政保姆、程序员、产品经理、猎头、雅思老师、
          在线医生、心理咨询师、股票经纪人、保险顾问、财务顾问、法律顾问、
          健身教练等....
        </p>
        <figure style={{ textAlign: "center" }}>
          <img src={`${globalOss}/phonesite/solc.png`} width="80%" alt="" />
        </figure>
      </div>
      {/* <p className="title">灵活用工服务企业</p> */}
      <div className={Css.warp}>
        <span className={Css.dxg}>灵活用工服务企业</span>
      </div>
      <div style={{ marginTop: 20 }}>
        <div>
          <p className={Css.dt}>线上行业</p>
          <div className={Css.cont4}>
            {dList[0].map((v) => (
              <div key={v[0]}>
                <div>
                  <img
                    src={require(`public/image/${v[0]}.png`).default}
                    width="15"
                    alt=""
                  />
                </div>
                <span className={Css.csp}>
                  <p>{v[1]}</p>
                  <span>{v[2]}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className={Css.dt}>线下行业</p>
          <div className={Css.cont4}>
            {dList[1].map((v) => (
              <div key={v[0]}>
                <div>
                  <img
                    src={require(`public/image/${v[0]}.png`).default}
                    width="15"
                    alt=""
                  />
                </div>
                <span className={Css.csp}>
                  <p>{v[1]}</p>
                  <span>{v[2]}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className={Css.dt}>其他行业</p>
          <div className={Css.cont4}>
            {dList[2].map((v) => (
              <div key={v[0]}>
                <div>
                  <img
                    src={require(`public/image/${v[0]}.png`).default}
                    width="15"
                    alt=""
                  />
                </div>
                <span className={Css.csp}>
                  <p>{v[1]}</p>
                  <span>{v[2]}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={Css.btn}>
        <Link to="/sub">立即预约</Link>
      </div>
    </div>
  );
}

export default Solution;
