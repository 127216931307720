export const globalOss = 'https://globalimg.oss-cn-shenzhen.aliyuncs.com/xiangong';

const root = 1 // 1 正式环境  2 测试环境
export const ROOT = root === 1 ? 'https://dcapi.xiangong.com' : (root === 2 ? 'https://dcapi-t.xiangong.com' : '')//开薪任务线上地址
export const CenterRoot = 'http://192.168.3.159:8088'//何刚
// export const ROOT = 'http://192.168.3.103:8088'//黄跃星

export const oss = root === 1 ? 'https://xiangongoss.oss-cn-shenzhen.aliyuncs.com/'
  : 'https://city-noie.oss-cn-chengdu.aliyuncs.com/'//测试环境osscs.

