import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Home from "page/home";
import Solution from "page/solution";
import Info from "page/info";
import Detail from "page/detail";
import About from "page/about";
import Sub from "page/sub";
import Application from "page/application";
import Recruit from "page/recruit";
import Css from "public/css/main.module.css";

let n = null;
const nav = [
  { title: "网站首页", url: "/" },
  { title: "解决方案", url: "/solution" },
  { title: "闲工资讯", url: "/info" },
  { title: "闲友招募", url: "/recruit" },
  { title: "关于闲工", url: "/about" },
  { title: "立即预约", url: "/sub" },
];
function Main() {
  const [ac, setAc] = useState(0);
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.location.pathname === "/sub") setAc(5);
    for (let i = 0; i < nav.length; i++) {
      if (window.location.pathname === "/") {
        setAc(i);
        break;
      }
      if (window.location.pathname.indexOf(nav[i].url) !== -1 && i !== 0) {
        setAc(i);
        break;
      }
    }
    n = isShow;
  }, []);
  function isShow() {
    if (window.location.pathname === "/sub") {
      setAc(5);
      moveNav(5);
    }
    if (window.location.pathname === "/recruit") {
      setShow(false);
    } else {
      setShow(true);
    }
  }
  function moveNav(i) {
    const li = document.getElementById("ul");
    if (li?.scrollLeft) {
      if (i === 1 || i === 2 || i === 0) li.scrollLeft = 0;
      if (i === 3) li.scrollLeft = 66;
      if (i === 4 || i === 5) li.scrollLeft = 122;
    }
  }
  window.scrollTo(0, 0);
  return (
    <div className={Css.App}>
      <Router>
        {/* <div className={Css.outer}>
          <ul className={Css.tab} id = 'ul'>
            {nav.map((v, i) => (
              <li key={i} className={ac === i ? Css.active : ""} onClick={()=>moveNav(i)}>
                <Link to={v.url}onClick={() => setAc(i)}>{v.title}</Link>
              </li>
            ))}
          </ul>
        </div> */}
        <div className={Css.mask} />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/solution" component={Solution} />
            <Route path="/about" component={About} />
            <Route path="/sub" component={Sub} />
            <Route path="/application" component={Application} />
            <Route path="/info/:detail" component={Detail} />
            <Route path="/info" component={Info} />
            <Route path="/recruit" component={Recruit} />
          </Switch>
        </main>
        {show ? (
          <footer>
            <div>
              <p>&copy;四川闲工科技有限公司</p>
              <p
                onClick={() => {
                  window.location.href = "https://beian.miit.gov.cn/";
                }}
              >
                蜀ICP备17043645号-2
              </p>
              <p>
                <img
                  src={require("public/image/ga_20201230141359.png").default}
                  width="15px"
                />{" "}
                川公网安备 51010602001265号
              </p>
            </div>
            <div></div>
            <div>
              <img
                src={require("public/image/qrcode.jpg").default}
                width="70vw"
              />
              <span style={{ fontSize: 12 }}>闲工服务公众号</span>
            </div>
          </footer>
        ) : null}
        <div className={Css.foo}></div>
      </Router>
    </div>
  );
}

export default Main;

export function showFooter() {
  if (n) n();
}
