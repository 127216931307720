import moment from "moment";
import { ROOT, oss, globalOss } from "config";
/**
 * 格式化时间
 * format格式:
 * yyyy-MM-dd:年月日
 * MM-dd:月日
 * yyyy-MM:年月
 * yyyy-MM-dd HH:mm:ss:年月日时分秒
 * yyyy-MM-dd HH:mm:年月日时分
 * HH:mm:时分
 * @param {Int} value 格式化的值 unix时间戳
 * @param {Moment} format 时间格式
 */
export function formatDateTime(value, format) {
  if (!format) format = "yyyy-MM-dd HH:mm:ss";
  //如果为HH:mm的时间进行一个字符串截取操作
  return format === "HH:mm"
    ? f(value, format).split("T")[1].substring(0, 5)
    : f(value, format);

  function f(value, format) {
    return moment(Number(value)).format(
      format === "yyyy-MM-dd"
        ? "YYYY-MM-DD"
        : format === "MM-dd"
        ? "MM-DD"
        : format === "yyyy-MM"
        ? "YYYY-MM"
        : format === "yyyy-MM-dd HH:mm:ss"
        ? "YYYY-MM-DD HH:mm:ss"
        : format === "yyyy-MM-dd HH:mm"
        ? "YYYY-MM-DD HH:mm"
        : format === "HH:mm"
        ? ""
        : "HH:mm"
    );
  }
}

/**
 * 获取图片地址
 * @param {String} url url路径
 * @param {Int} size 图片清晰度  120 250 480. size默认值为120
 * @param {Boolean} file 默认 false, 传 true 为文件
 */
export function getImg(url, size, file = false) {
  if (!url) return "";
  if (!size) size = 120;
  // console.log(url.search(/ttps:\/\//i));
  return url.search(/ttps:\/\//i) !== -1
    ? url
    : oss + `${url}${file ? "" : `/${size}`}`;
}

// 微信分享
export async function wxConfig(url) {
  const res = await fetch(`${ROOT}/weixin/getWechatSignature`, {
    method: "POST",
    headers: {
      "accept-language": "null;1;zh-CN,zh",
    },
    body: JSON.stringify({
      locationUrl: url,
    }),
  });
  const res_1 = JSON.stringify(res);
  window.wx.config({
    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: res_1.data?.appId,
    timestamp: res_1.data?.timestamp,
    nonceStr: res_1.data?.nonceStr,
    signature: res_1.data?.signature,
    jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
  });
}
export function shares(desc, url) {
  window.wx.ready(() => {
    window.wx.updateAppMessageShareData({
      title: "闲工", // 分享标题
      desc: desc, // 分享描述
      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: globalOss + "/xg.png", // 分享图标
    });
  });
}
