import React, { useEffect } from "react";
import Css from "public/css/recruit.module.css";
import { showFooter } from "page/main";
import { globalOss} from 'config';
export default function Recruit() {
  useEffect(() => {
    showFooter();
  }, []);

  return (
    <div>
      <img
        src={`${globalOss}/phonesite/x_zm_1.png`}
        alt=""
        className={Css.himg}
      />
      <div className={Css.warp}>
        <span className={Css.dxg}>闲友模式</span>
      </div>
      <div className={Css.img2}>
        <div className={Css.title}>
          <span>供给</span>
          <span>匹配</span>
          <span>需求</span>
        </div>
        <img
          src={require("public/image/new/x_zm_2.png").default}
          alt=""
          className={Css.himg}
        />
        <div className={Css.title3}>
          以互联网分享式营销，让闲友参与事业发展。
        </div>
      </div>
      <div className={Css.warp}>
        <span className={Css.dxg}>灵活用工服务人群</span>
      </div>
      <div className={Css.img3}>
        <div className={Css.title4}>
          <div></div>
          <p>成为闲友6大优势</p>
          <div></div>
        </div>
        <div className={Css.title5}>
          <img
            src={`${globalOss}/phonesite/x_zm_3.png`}
            alt=""
            className={Css.himg}
          />
        </div>
      </div>
   <div className={Css.bottom}>
        <div>
            <p>关注闲工公众号</p>
            <img src={require('public/image/qrcode.jpg').default} width="101px" />
            <div>
                •<span>扫描二维码进入公众号</span>
                <br />
                •<span>点击右下角成为闲者</span>
            </div>
        </div>
        <img className={Css.bimg} src={`${globalOss}/phonesite/x_zm_4.png`} width={170} alt="" />
      </div>
    </div>
    
  );
}
