import React, { useEffect } from "react";
import Css from "public/css/home.module.css";
import { globalOss } from "config";
import { wxConfig, shares } from "common/methods";
import { Link } from "react-router-dom";
import { showFooter } from "page/main";
function Home() {
  window.scrollTo(0, 0);
  useEffect(() => {
    showFooter();
    shares("助企业降本增效,促个人灵活就业", window.location.href);
    wxConfig(window.location.href);
  }, []);
  return (
    <div>
      <img
        src={`${globalOss}/phonesite/x_h.png`}
        style={{ width: "100%" }}
        className="jt"
        alt=""
      />
      <div className={Css.warp}>
        <span className={Css.dxg}>打闲工</span>
        <div className={Css.shu}></div>
        <span className={Css.weiht}>无需企业承担五险一金</span>
      </div>
      <div className={Css.cont1}>
        <img
          src={`${globalOss}/phonesite/x_dxg.png`}
          style={{ width: "100%" }}
          alt=""
        />
      </div>
      <div className={Css.warp}>
        <span className={Css.dxg}>用闲者</span>
        <div className={Css.shu}></div>
        <span className={Css.weiht}>无需企业承担五险一金</span>
      </div>
      <div className={Css.cont2}>
        <div >
          <div className={Css.pdiv}>
            <div className={Css.fdiv}>
              <div className={Css.divs}></div>
              <p>人才： 从 “为我所有” 到 “为我所用”</p>
              <div className={Css.divs}></div>
            </div>
            <p>
              把非核心、模块化、可计价的闲散任务交给非全日制、专业、娴熟的零工来处理，
              可减少企业员工数量及降低部分专业能力需求，实现降本增效。
            </p>
          </div>
          <div className={Css.fd}>
            <div>
              <div>
                <p>员工</p>
                <p>与企业建立劳动关系</p>
                <div className={Css.bak}>
                  <img
                    src={`${globalOss}/phonesite/x_yxz_l.png`}
                    alt="1"
                  />
                </div>
              </div>
              <div className={Css.cir} />
              <div>
                <p>闲者</p>
                <p>与企业建立劳动关系</p>
                <div className={Css.bak}>
                  <img
                    src={`${globalOss}/phonesite/x_yxz_r.png`}
                    alt="1"
                  />
                </div>
              </div>
            </div>
            <p>
              企业多元化用工，有助于减轻负担！ 有助于聚焦核心业务！有助于凝聚核心人员！
            </p>
          </div>
        </div>
        <div>
          <div className={Css.pdiv}>
            <div>
              <div className={Css.divs}></div>
              <p>任务： 从 “坐过山车” 到 “应对自如”</p>
              <div className={Css.divs}></div>
            </div>
            <p>
              把产销旺季期或项目高发期的阶段类人力需求通过灵活用工方式处理，可解决波动性工作量对企业员工数量需求的不匹配问题。
            </p>
          </div>
          <div className={Css.fs}>
            <img
              src={`${globalOss}/phonesite/x_qxt.png`}
              width="100%"
              alt="1"
            />
          </div>
        </div>
      </div>
      <div className={Css.warp}>
        <span className={Css.dxg}>报酬结算</span>
      </div>
      <div className={Css.cont3}>
        {/* <div>
        <p>灵活用工:  从 “吃力不讨好” 到 “专业化服务”</p>
        <span>通过闲工平台提供灵活用工共享服务，可以有效解决企业直接与零工合作存在的问题</span>
      </div> */}
        <p>灵活用工案列分析</p>
        <div className={Css.linediv} style={{ background: "white" }}>
          {/* <div className={Css.divs2}></div> */}
          <span className={Css.line}>
            某企业A(闲主)同带货主播小艾合作，小艾单月收入3万元
          </span>
          {/* <div className={Css.divs2}></div> */}
        </div>
        <img
          src={`${globalOss}/phonesite/x_fs_1.png`}
          width="100%"
          alt=""
        />
        <img
          src={`${globalOss}/phonesite/x_fs_2.png`}
          width="100%"
          alt=""
        />
        <p>
          采取闲工服务，闲主（企业雇主）合规获取增值税专票抵扣成本，降低税负，主播到手收入增多，企业同个人双获利！
        </p>
      </div>
      <div className={Css.warp}>
        <span className={Css.dxg}>闲工优势</span>
      </div>
      <div className={Css.xgys}>
        <p>我们的优势</p>
        <div>
          <div>
            <div className={Css.divs2}>
              <img
                className={Css.imgs}
                src={`${globalOss}/phonesite/x_ys_1.png`}
                alt=""
              />
              合法合规
            </div>
            <p>按国家法律法规执行,授权代征个人所得税</p>
          </div>
          <div>
            <div className={Css.divs2}>
              <img
                className={Css.imgs}
                src={`${globalOss}/phonesite/x_ys_2.png`}
                alt=""
              />
              资金安全
            </div>
            <p>银行开立专属账户,定向支付报酬费用</p>
          </div>
          <div>
            <div className={Css.divs2}>
              <img
                className={Css.imgs}
                src={`${globalOss}/phonesite/x_ys_3.png`}
                alt=""
              />
              降低税负
            </div>
            <p>劳务合作转经营合作,开具增值税专票节税高达80%</p>
          </div>
          <div>
            <div className={Css.divs2}>
              <img
                className={Css.imgs}
                src={`${globalOss}/phonesite/x_ys_4.png`}
                alt=""
              />
              共享合作
            </div>
            <p>收益与合作方共享,长期受益</p>
          </div>
        </div>
      </div>
      <div className={Css.warp}>
        <span className={Css.dxg}>闲工服务流程</span>
      </div>
      <div className={Css.xgfwlc}>
        <p>闲主（企业雇主）服务流程</p>
      </div>
      <div className={Css.xz}>
        <div>
          <div>
            <span>01.</span>
            <div>
              <p>自主注册</p>
              <p>营业执照即可</p>
            </div>
          </div>
          <div>
            <img src={`${globalOss}/phonesite/x_lc.png`} alt="" />
          </div>
          <div>
            <span>02.</span>
            <div>
              <p>平台签约</p>
              <p>签署闲工平台服务协议</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Css.xz}>
        <div>
          <div>
            <span>03.</span>
            <div>
              <p>发布任务</p>
              <p>在线发布任务,与闲者确认合作关系</p>
            </div>
          </div>
          <div>
            <img src={`${globalOss}/phonesite/x_lc.png`} alt="" />
          </div>
          <div>
            <span>04.</span>
            <div>
              <p>任务验收</p>
              <p>闲者提交任务,闲主审核确认</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Css.xz}>
        <div>
          <div>
            <span>05.</span>
            <div>
              <p>报酬代发</p>
              <p>
                在线提交代发报酬信息,要求闲商（财税服务商）
                向闲者发放报酬，并为闲者完税
              </p>
            </div>
          </div>
          <div>
            <img src={`${globalOss}/phonesite/x_lc.png`} alt="" />
          </div>
          <div>
            <span>06.</span>
            <div>
              <p>开取发票</p>
              <p>线上申请发票开具,查看发票物流进度</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Css.xgfwlc}>
        <p>闲者（自由职业者）服务流程</p>
      </div>
      <div className={Css.xz}>
        <div>
          <div>
            <span>01.</span>
            <div>
              <p>用户签约</p>
              <p>在线签约，完善身份证、银行卡、手机号信息即可完成</p>
            </div>
          </div>
          <div>
            <img src={`${globalOss}/phonesite/x_lc.png`} alt="" />
          </div>
          <div>
            <span>02.</span>
            <div>
              <p>提供服务</p>
              <p>为企业提供服务</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Css.xz}>
        <div>
          <div>
            <span>03.</span>
            <div>
              <p>收入</p>
              <p>银行卡直接入账</p>
            </div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={Css.btn}>
        <Link to="/sub">立即预约</Link>
      </div>
    </div>
  );
}

export default Home;
